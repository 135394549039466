// @ts-ignore
import fetch from "isomorphic-fetch";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { localStorageGetItem } from "../service/storage";

const authLink = setContext((_, { headers }) => {
  const token = localStorageGetItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    new HttpLink({
      uri: `${process.env.STRAPI_BASE_URL}/graphql`,
      fetch,
    })
  ),
});

export default client;
