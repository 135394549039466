exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-academy-tumar-detail-tsx": () => import("./../../../src/pages/academy-tumar-detail.tsx" /* webpackChunkName: "component---src-pages-academy-tumar-detail-tsx" */),
  "component---src-pages-academy-tumar-tsx": () => import("./../../../src/pages/academy-tumar.tsx" /* webpackChunkName: "component---src-pages-academy-tumar-tsx" */),
  "component---src-pages-analysis-and-prices-tsx": () => import("./../../../src/pages/analysis-and-prices.tsx" /* webpackChunkName: "component---src-pages-analysis-and-prices-tsx" */),
  "component---src-pages-analysis-detail-tsx": () => import("./../../../src/pages/analysis-detail.tsx" /* webpackChunkName: "component---src-pages-analysis-detail-tsx" */),
  "component---src-pages-application-form-tsx": () => import("./../../../src/pages/application-form.tsx" /* webpackChunkName: "component---src-pages-application-form-tsx" */),
  "component---src-pages-application-tsx": () => import("./../../../src/pages/application.tsx" /* webpackChunkName: "component---src-pages-application-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-cabinet-tsx": () => import("./../../../src/pages/cabinet.tsx" /* webpackChunkName: "component---src-pages-cabinet-tsx" */),
  "component---src-pages-clinical-blood-test-tsx": () => import("./../../../src/pages/clinical-blood-test.tsx" /* webpackChunkName: "component---src-pages-clinical-blood-test-tsx" */),
  "component---src-pages-complex-service-packages-detail-tsx": () => import("./../../../src/pages/complex-service-packages-detail.tsx" /* webpackChunkName: "component---src-pages-complex-service-packages-detail-tsx" */),
  "component---src-pages-complex-service-packages-tsx": () => import("./../../../src/pages/complex-service-packages.tsx" /* webpackChunkName: "component---src-pages-complex-service-packages-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-for-doctors-tsx": () => import("./../../../src/pages/for-doctors.tsx" /* webpackChunkName: "component---src-pages-for-doctors-tsx" */),
  "component---src-pages-franchaising-tsx": () => import("./../../../src/pages/franchaising.tsx" /* webpackChunkName: "component---src-pages-franchaising-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-history-application-detail-tsx": () => import("./../../../src/pages/history-application-detail.tsx" /* webpackChunkName: "component---src-pages-history-application-detail-tsx" */),
  "component---src-pages-house-call-tsx": () => import("./../../../src/pages/house-call.tsx" /* webpackChunkName: "component---src-pages-house-call-tsx" */),
  "component---src-pages-how-to-prepare-tsx": () => import("./../../../src/pages/how-to-prepare.tsx" /* webpackChunkName: "component---src-pages-how-to-prepare-tsx" */),
  "component---src-pages-in-process-tsx": () => import("./../../../src/pages/in-process.tsx" /* webpackChunkName: "component---src-pages-in-process-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/new-password.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-news-detail-tsx": () => import("./../../../src/pages/news-detail.tsx" /* webpackChunkName: "component---src-pages-news-detail-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-order-home-tsx": () => import("./../../../src/pages/orderHome.tsx" /* webpackChunkName: "component---src-pages-order-home-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-preparation-for-tests-tsx": () => import("./../../../src/pages/preparation-for-tests.tsx" /* webpackChunkName: "component---src-pages-preparation-for-tests-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-pages-public-condition-tsx": () => import("./../../../src/pages/public-condition.tsx" /* webpackChunkName: "component---src-pages-public-condition-tsx" */),
  "component---src-pages-search-result-tsx": () => import("./../../../src/pages/search-result.tsx" /* webpackChunkName: "component---src-pages-search-result-tsx" */),
  "component---src-pages-typical-information-tsx": () => import("./../../../src/pages/typical-information.tsx" /* webpackChunkName: "component---src-pages-typical-information-tsx" */)
}

