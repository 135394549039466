import exp from "constants";

export const isBrowser = () => typeof window !== "undefined";

export const localStorageSetItem = (key: string, value: string) =>
  isBrowser() && window.localStorage.setItem(key, value);

export const localStorageGetItem = (key: string) =>
  (isBrowser() && window.localStorage.getItem(key)) || null;

const ME_KEY = "me";
const ME_TTL = 60 * 60 * 1000; // 1 hour
export const localStorageGetProfile = () => {
  if (isBrowser()) {
    const existingProfileRaw = window.localStorage.getItem(ME_KEY);
    if (existingProfileRaw === null) {
      return null;
    }
    const existingProfile = JSON.parse(existingProfileRaw);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > existingProfile.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(ME_KEY);
      return null;
    }
    return existingProfile.value;
  }
  return null;
};

export const localStorageSetProfile = (profile: any) => {
  if (isBrowser()) {
    const now = new Date();

    localStorage.setItem(
      ME_KEY,
      JSON.stringify({
        value: profile,
        expiry: now.getTime() + ME_TTL,
      })
    );
  }
};

export const localStorageDropProfile = () => {
  if (isBrowser()) {
    localStorage.removeItem(ME_KEY);
    localStorage.removeItem("token");
  }
};

const BASKET_KEY = "basket";
const emptyBasket = {
  analyses: [],
};
export const localStorageGetBasket = () => {
  if (isBrowser()) {
    const existingBasketRaw = window.localStorage.getItem(BASKET_KEY);
    if (existingBasketRaw === null) {
      return emptyBasket;
    }
    return JSON.parse(existingBasketRaw);
  }
  return emptyBasket;
};
export const localStorageAddAnalysisToBasket = (analysisId: string) => {
  if (isBrowser()) {
    const basket = localStorageGetBasket();

    const newAnalyses = [...basket.analyses, analysisId].filter(
      (item, index, array) => array.indexOf(item) === index
    );

    window.localStorage.setItem(
      BASKET_KEY,
      JSON.stringify({
        ...basket,
        analyses: newAnalyses,
      })
    );
  }
};

export const localStorageRemoveAnalysisFromBasket = (analysisId: string) => {
  if (isBrowser()) {
    const basket = localStorageGetBasket();

    const newAnalyses = [...basket.analyses].filter(
      (item, index, array) => array.indexOf(analysisId) !== index
    );

    window.localStorage.setItem(
      BASKET_KEY,
      JSON.stringify({
        ...basket,
        analyses: newAnalyses,
      })
    );
  }
};

export const localStorageResetBasket = () => {
  if (isBrowser()) {
    window.localStorage.removeItem(BASKET_KEY);
  }
};

export const localStorageGetBasketCount = () => {
  if (isBrowser()) {
    const basket = localStorageGetBasket();

    return basket.analyses.length;
  }
  return 0;
};
